import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import toast from 'react-hot-toast'
import { getClientSecret } from "../../sevices/apiService";
import { CheckoutForm } from "./CheckoutForm";
import { fetchIpAddress } from "../../utils/fetchIpAddress";
import { CgSpinnerAlt } from 'react-icons/cg'
import { errorToSlack } from "../../utils/errorToSlack";

const getDomainWithoutTLD = (hostname) => {
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts.slice(-2, -1)[0];
  } else if (parts.length === 2) {
    return parts[0];
  }
  return hostname;
};
const domain = window.location.hostname;
const domainWithoutTLD = getDomainWithoutTLD(domain);

const generateLogoText = (domain) => {
  return domain.charAt(0).toUpperCase() + domain.slice(1);
};
const logoText = generateLogoText(domainWithoutTLD);

function ExternalPage() {
  const [itemData, setItemData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const product = queryParams.get('product');
  const price = queryParams.get('price');
  const custom_field = queryParams.get('custom_field');
  const stripePromise = loadStripe(itemData.public_key);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ipAddress = await fetchIpAddress();
        const getItem = await getClientSecret(product, price, ipAddress, custom_field);

        if (getItem.data.error_message && getItem.data.redirect_domain) {
          const currentParams = location.search;
          window.location.href = `${getItem.data.redirect_domain}/pay${currentParams}`;
          return;
        }
        setItemData(getItem.data);
        localStorage.removeItem('success');
        localStorage.removeItem('failure');
        localStorage.setItem('success', getItem.data.success_redirect);
        localStorage.setItem('failure', getItem.data.failure_redirect);
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message);
        errorToSlack(`Failed getClientSecret. ${error.message}`);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [product, price, custom_field, location.search]);

  const appearance = {
    theme: 'stripe',
  };
  const stripeOptions = {
    clientSecret: itemData.client_secret,
    appearance,
    locale: 'en'
  };

  return (
    <div className="bg-base-200 h-screen flex items-center">
      <div className="card mx-auto w-full max-w-5xl shadow-xl">
        <div className="py-8 px-4 bg-red-100 my-6">
          <div className="w-full mx-auto text-center font-bold text-sm">
            <p className="text-pink-600">
              In some cases payment form may not work (VPN, TOR, proxy, old browser, etc). Need help?
              <a href={`mailto:relaxservices@protonmail.com`} className="text-indigo-600 ml-3 font-bold">Contact us</a>
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 bg-base-100 rounded-xl">
          <div className="hero rounded-l-xl" style={{ backgroundColor: '#f7f9fc' }}>
            <div className="py-12 flex flex-col">
              <div className="max-w-md mx-auto text-center">
                {itemData.logo ? (
                  <img src={itemData.logo} className="w-44 inline-block" alt="logo" />
                ) : (
                  <span className="w-44 h-44 flex text-indigo-900 justify-center items-center text-2xl font-bold">{logoText}</span>
                )}
              </div>
            </div>
          </div>
          <div className="my-auto md:flex flex-col justify-center px-6 py-12 align-middle lg:px-8">
            <>
              {itemData.price ?
                <div className="my-auto align-middle">
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-20 md:mt-0">
                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                      Make payment:
                      <span className="text-indigo-600 ml-3">{itemData.currency}{itemData.price}</span>
                    </h2>
                    <p className="text-center text-md font-medium leading-9 tracking-tight text-indigo-600">
                      Pay for {itemData.item_name} product on
                    </p>
                    <p className="text-center text-gray-600 text-sm"> {itemData.site_name} </p>
                  </div>

                  <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    {isLoading ? <CgSpinnerAlt
                      className="mr-3 h-5 w-5 animate-spin"
                      viewBox="0 0 24 24"
                    ></CgSpinnerAlt> : <Elements options={stripeOptions} stripe={stripePromise}>
                      <CheckoutForm clientSecret={itemData.client_secret} itemData={itemData} />
                    </Elements>}
                  </div>
                </div>
                :
                <span className="loading loading-spinner text-primary mx-auto"></span>
              }
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExternalPage